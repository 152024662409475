import jwt from "jsonwebtoken";
import dayjs from "dayjs";

export const TOKEN_REFRESH_EXPIRATION_BUFFER_SECONDS = 60;

/**
 * Determines if the token is expired
 * @param {string} accessToken - The user's access JWT token
 * @return {boolean}
 */
export const isTokenExpired = (accessToken) => {
  try {
    if (accessToken === undefined || accessToken == null || accessToken.length === 0) {
      return true;
    }
    const decodedToken = jwt.decode(accessToken, {});
    return dayjs().isAfter(dayjs.unix(decodedToken.exp).subtract(TOKEN_REFRESH_EXPIRATION_BUFFER_SECONDS, "second"));
  } catch (e) {
    return true;
  }
};

export const buildLoginResponse = (response) => {
  const result = {};
  if (response.challenge) {
    result.challenge = response.challenge;
  }
  if (response.parameters) {
    result.parameters = response.parameters;
  }
  result.success = response.success;
  result.code = response.result;
  return result;
};
