export const browserIsChrome = () => navigator.userAgent.indexOf("Chrome") > -1;
export const browserIsFirefox = () => navigator.userAgent.indexOf("Firefox") > -1;
export const browserIsOpera = () => navigator.indexOf("OP") > -1 && browserIsChrome === false;
export const browserIsSafari = () => navigator.userAgent.indexOf("Safari") > -1 && browserIsChrome === false;

export const launchUri = (url) => {
  const SELF = '_self';
  const windowProxy = window.open(url, SELF);
  if (windowProxy) {
    return Promise.resolve(windowProxy);
  } else {
    return Promise.reject();
  }
};
