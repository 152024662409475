import axios, { AxiosRequestConfig, Method } from "axios";
import { setupApi } from "../apiSetup";

const baseURL = "/";
const timeout = 30000;

const axiosInstance = axios.create();
setupApi(axiosInstance);

const execute = async (
  method: Method,
  partialPath: string,
  data: AxiosRequestConfig["data"] = undefined,
  params: AxiosRequestConfig["params"] = undefined,
  headers: AxiosRequestConfig["headers"] = undefined
) => {
  const options: AxiosRequestConfig = {
    method,
    url: partialPath,
    withCredentials: true,
    baseURL,
    timeout
  };
  if (data) {
    options.data = data;
  }
  if (params) {
    options.params = params;
  }
  if (headers) {
    options.headers = headers;
  }
  return await axiosInstance.request(options);
};

const get = async (path: string, params: AxiosRequestConfig["params"] = undefined, headers: AxiosRequestConfig["headers"] = undefined) => {
  return execute("GET", path, null, params, headers);
};

const post = async (path: string, data: AxiosRequestConfig["data"], headers: AxiosRequestConfig["headers"] = undefined) => {
  return execute("POST", path, data, headers);
};

const deleteMethod = async (path: string, data: AxiosRequestConfig["data"] = {}, headers: AxiosRequestConfig["headers"] = undefined) => {
  return execute("DELETE", path, data, headers);
};

const put = async (path: string, data: AxiosRequestConfig["data"], headers: AxiosRequestConfig["headers"] = undefined) => {
  return execute("PUT", path, data, undefined, headers);
};

export const ApiRequest = {
  get,
  post,
  delete: deleteMethod,
  put
};
