// import.meta.env is how Vite gets process.env
// Provide fallback empty object since import.meta.env is not defined when running Storybook
const env = import.meta.env || {};

export const LOCAL = "local";
export const DEV = "development";
export const PROD = "production";

export class RuntimeEnvironment {
  environment;
  statusServiceEndpoint;
  rollbarToken;

  constructor() {
    this.environment = env.REACT_APP_ENVIRONMENT ??
      (isLocal() ? "local" : env.NODE_ENV ?? "development");

    this.statusServiceEndpoint = env.REACT_APP_STATUS_SERVICE_ENDPOINT ?? "https://status.api.dev.flashparking.com";
    this.defaultRedirect = env.REACT_APP_DEFAULT_SSO_REDIRECT ?? "https://portal.dev.flashparking.com";
    this.rollbarToken = env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN;

    // analytics
    this.insightsBaseUrl = env.REACT_APP_INSIGHTS_BASE_URL;
    this.insightsApiKey = env.REACT_APP_INSIGHTS_API_KEY;
  }
}

function isLocal() {
  return window?.location?.hostname === "localhost" || window?.location?.hostname === "127.0.0.1";
}

const Environment = new RuntimeEnvironment();
Object.freeze(Environment);
export default Environment;
