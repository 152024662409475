import {AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponseHeaders} from "axios";

export type FlashServiceErrorResponse = { code?: string; details?: object; message?: string };

export type FlashApiErrorResponse = {
	requestId: string;
	requestEndTime: string;
	requestStartTime: string;
	success: boolean;
	error: FlashServiceErrorResponse;
};

export type FlashAxiosError = Partial<
	Pick<AxiosError<FlashApiErrorResponse>, "request" | "response" | "message">
> & { config: AxiosRequestConfig };

export class ApiError extends Error {
	cause?: FlashAxiosError;
	date: Date;
	statusCode: number;
	errorCode: string;
	details?: any;
	request: {
		url: string;
		method?: string;
		headers?: AxiosRequestHeaders;
		data?: string;
	};
	response?: {
		headers?: AxiosResponseHeaders;
		data?: FlashApiErrorResponse;
	};

	constructor(error?: FlashAxiosError) {
		const { response, config } = error || {};
		const { data, headers } = response || {};
		const { error: dataError } = data || {};

		const message = dataError?.message ?? error?.message ?? "NO MESSAGE";
		super(message, { cause: error });
		this.name = "ApiError";

		// Error Details
		this.cause = error;
		this.statusCode = response?.status ?? 500;
		this.errorCode = dataError?.code ?? "unknown"
		this.details = dataError?.details;
		this.date = new Date();

		//Request
		const { baseURL, method, url } = config || {};
		const reqHeaders = config?.headers;
		delete reqHeaders?.Authorization;
		this.request = {
			url: `${baseURL}${url}`,
			method,
			headers: reqHeaders,
			data: config?.data
		};

		//Response
		this.response = {
			...(headers ? { headers } : {}),
			...(data ? { data } : {})
		};

		// Maintains proper stack trace for where our error was thrown (only available on V8)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ApiError);
		}
	}
}
