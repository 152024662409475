import Cookies from "universal-cookie";

const localStorage = window.localStorage;
const cookies = new Cookies();

export const getLocalStorage = (name) => {
  const data = localStorage.getItem(name);
  return JSON.parse(data);
};

export const getLocalStorageWithExpiration = (name) => {
  const item = getLocalStorage(name);
  if(item){
    if(item.expiration > (new Date().getTime())){
      return item.value;
    }
    else{
      removeFromLocalStorage(name);
      return null;
    }
  }
};

export const setLocalStorage = (name, value) => {
  const data = JSON.stringify(value);
  localStorage.setItem(name, data);
};

/***
 * Puts an item in storage with a TTL
 * @param name - key of item
 * @param value - item value
 * @param ttl - Time to Live in seconds
 */
export const setLocalStorageWithExpiration = (name, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiration: now.getTime() + (ttl * 1000)
  };
  setLocalStorage(name, item)
};

export const removeFromLocalStorage = (name) => {
  localStorage.removeItem(name);
};

export const getCookie = (key) => {
  return cookies.get(key);
};

export const removeCookie = (key) => {
  setCookie(key, null, 'Thu, 01 Jan 1970 00:00:00 UTC');
};

export const setCookie = (name, value, expire) => {
  document.cookie = `${name}=${!value ? '' : encodeURIComponent(value)
  }; SameSite=None; Secure; Expires=${expire}; Path=/;`;
};
