import { History, LocationDescriptorObject } from "history";

import { AuthService, authResponseCodeEnum } from ".";
import { AuthChallengeResult } from "lib/context/AuthenticationContext";
import {launchUri} from "../../utils/browser";

export type HandleAuthResponseOptions = {
  /** `History` object from `react-router` */
  history: History;
  /** Function to invoke to update the challenge payload in context */
  setAuthChallengeResult: (payload: AuthChallengeResult) => void | Promise<void>;
  /** Data passed to the service as part of the challenge request */
  data?: Record<string, string | undefined>
  /** Result of a challenge request */
  challengeResult?: AuthChallengeResult
  /**
   * Prevent redirect for handling response code
   *
   * This can be used when issuing a "resend code" request
   */
  preventRedirect?: Partial<Record<authResponseCodeEnum, boolean>>;
};

const {
  confirmation_code_required,
  new_password_required,
  mfa_setup_required,
  sms_mfa,
  logged_in,
} = authResponseCodeEnum;

/**
 * Responds to auth challenge response with appropriate redirect and state change
 *   behavior
 */
export async function handleAuthChallengeResponse(
  options: HandleAuthResponseOptions
) {
  const {
    history,
    setAuthChallengeResult,
    data,
    challengeResult,
    preventRedirect = {},
  } = options;

  const params = getQueryStringParamsFromHistory(history);

  switch (challengeResult?.code) {
    // both of these codes take user to password required page
    case confirmation_code_required:
    case new_password_required: {
      setAuthChallengeResult(challengeResult);
      redirect(history, { pathname: "/password/required" }, preventRedirect[challengeResult.code]);
      break;
    }

    case mfa_setup_required: {
      setAuthChallengeResult(challengeResult);
      redirect(history, { pathname: "/mfa/required" }, preventRedirect[mfa_setup_required]);
      break;
    }

    case sms_mfa: {
      setAuthChallengeResult(challengeResult);
      const newParams = new URLSearchParams(history.location.search);
      if (data?.phoneNumber) {
        newParams.append("phoneNumber", data.phoneNumber);
      }
      redirect(
        history,
        { pathname: "/login/mfa", search: newParams.toString() },
        preventRedirect[sms_mfa]
      );
      break;
    }

    case logged_in: {
      const attributeChanged = params["attributeChanged"];
      if (attributeChanged) {
        redirect(history, { pathname: "/verify/attribute" }, preventRedirect[logged_in]);
        break;
      }

      const setNewPassword = params["setNewPassword"];
      if (setNewPassword) {
        const newParams = new URLSearchParams(history.location.search);
        await launchUri("/profile/change-password?" + newParams.toString());
        break;
      }

      const redirectUri = params["redirectUri"] || params["referrer"];
      if (redirectUri) {
        await AuthService.authorizeClient({ ...params, redirectUri });
        break;
      }

      redirect(history, { pathname: "/login" }, preventRedirect[logged_in]);
      break;
    }

    default: {
      break;
    }
  }
}

/**
 * Redirects using `replace` strategy to prevent navigating back from new location
 *   and includes current search params when redirecting
 */
function redirect(
  history: HandleAuthResponseOptions["history"],
  location: LocationDescriptorObject,
  preventRedirect?: boolean
) {
  if (!preventRedirect) {
    history.replace({
      search: history.location.search,
      ...location,
    });
  }
}

/* Returns a JSON object from the querystring parameters on `history.location.search` */
export function getQueryStringParamsFromHistory(history: History) {
  return Object.fromEntries(
    new URLSearchParams(history.location.search).entries()
  );
}
