import { FlashFormFieldValidationMessageProps } from "@flashparking-inc/ux-lib-react";
import { TFunction } from "i18next";
import { IAMAccessPolicy } from "./accountPolicy";

/** Validates a password string using the provided security policy */
export function validatePasswordUsingPolicy(options: {
  t: TFunction;
  policy?: Partial<IAMAccessPolicy>;
  password: string;
  passwordFieldTouched?: boolean;
  /** Set `initialize` to `true` to get all messages as type "required" */
  initialize?: boolean;
}): FlashFormFieldValidationMessageProps[] {
  const { t, policy, password, passwordFieldTouched, initialize } = options;
  const messages: FlashFormFieldValidationMessageProps[] = [];

  if (!policy?.passwordComplexity) {
    return messages;
  }

  const { minLength, contains } = policy.passwordComplexity;

  if (minLength) {
    messages.push({
      message: t("LOGIN:RULES_CHARACTERS_MIN_LENGTH", { minLength }),
      type: passwordValidationMessageType(password.length >= minLength),
    });
  } else if (passwordFieldTouched) {
    messages.push({
      type: "invalid",
      message: t("COMMON:PASSWORD_REQUIRED"),
    });
  }

  if (contains.upper) {
    messages.push({
      message: t("LOGIN:RULES_UPPER"),
      type: passwordValidationMessageType(!!password.match(/[A-Z]/g)),
    });
  }

  if (contains.lower) {
    messages.push({
      message: t("LOGIN:RULES_LOWER"),
      type: passwordValidationMessageType(!!password.match(/[a-z]/g)),
    });
  }

  if (contains.number) {
    messages.push({
      message: t("LOGIN:RULES_NUMBER"),
      type: passwordValidationMessageType(!!password.match(/[0-9]/g)),
    });
  }

  if (contains.special) {
    messages.push({
      message: t("LOGIN:RULES_SPECIAL_CHAR"),
      type: passwordValidationMessageType(!!password.match(/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/g)),
    });
  }

  if (initialize) {
    return messages.map((m) => ({ ...m, type: "requirement" }));
  }

  return messages;
}

export function passwordValidationMessageType(valid: boolean) {
  return valid ? "valid" : "requirement";
}
