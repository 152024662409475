import { ApiRequest } from "../apiClient/requests/apiRequest";
import {
  ACCESS_TOKEN_KEY,
  ID_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  PKCE_STORAGE_KEY,
  CLIENT_CONFIG_STORAGE_KEY,
  CLEAR_SESSION_STORAGE_KEY,
  REDIRECT_PATH_STORAGE_KEY
} from "../../consts";
import {
  getCookie, getLocalStorage,
  getLocalStorageWithExpiration,
  removeCookie, removeFromLocalStorage,
  setLocalStorage,
  setLocalStorageWithExpiration
} from "../../utils/storage";
import { UserPool } from 'cognito-srp';
import DateHelper from "./dateHelper";
import {
  launchUri
} from "../../utils/browser";
import axios from "axios";
import { buildLoginResponse, isTokenExpired } from "./utils";
import {generateChallenge, generateRandom} from "../../utils/encoding";
import {decodeClientStateFromQS} from "../../utils/http";
import {authResponseCodeEnum} from "./index";
import {Logger} from "../../utils/logging";

const CLIENT_TYPE = "sso";

/**
 * @typedef {Object} ClientConfig
 * @property {string} poolName - The active Cognito Pool name (this is the id w/o the region)
 * @property {Object} idProviders - A map of domain-to-providers
 */

export class AuthService {

  static get SSORedirectUri() {
    const redirectUri = window.location.origin;
    return `${redirectUri}/provider`; //note: this must be a registered redirect URI (callback) for the SSO ClientType
  }

  static get SSOLogoutUri() {
    const redirectUri = window.location.origin;
    return `${redirectUri}/logout`;  //note: this must be a registered logout URI for the SSO ClientType
  }

  static get SSOClientType() { return CLIENT_TYPE; }

  static get accessToken() { return getCookie(ACCESS_TOKEN_KEY); }

  /**
   * @return {Promise<ClientConfig>}
   */
  static async getClientConfig() {
    let config = getLocalStorageWithExpiration(CLIENT_CONFIG_STORAGE_KEY);
    if (!config) {
      const res = await axios.get("/auth/client");
      config = res.data;
      setLocalStorageWithExpiration(CLIENT_CONFIG_STORAGE_KEY, config, 300);
    }
    return config;
  }

  /**
   * Checks the authentication of the user and initiates the refresh/code-flow process for client portals
   * @param params
   * @return {Promise<{cachedAuth: boolean, forceLogout: boolean, redirectPath: *, error}|{cachedAuth: boolean, forceLogout: boolean, redirectPath: *, error: *}>}
   */
  static async checkAuthenticationState(params, preventRedirect = false) {
    const { error, errorDescription, forceAuth } = params;
    const result = {
      cachedAuth: false,
      forceLogout: false,
      redirectPath: undefined,
      error: undefined
    };

    // Case 1 - We have an error. Stop all processing and return the error
    if (error) {
      if (forceAuth === "true") {
        AuthService.logout();
      }
      let errorMessage;
      if (error) {
        errorMessage = error;
        if (errorDescription) {
          errorMessage = `[${error}]: ${errorDescription}`
        }
      }
      return {
        ...result,
        error: errorMessage
      };
    }

    // Case 2 - User has valid credentials and can initiate the client code-flow
    const { accessToken, idToken, refreshToken } = await AuthService.getTokens(true);
    if (accessToken && idToken && refreshToken) {
      // handle cached login from client portal - begin code flow
      if (params.redirectUri) {
        await AuthService.authorizeClient(params);
      } else if (!preventRedirect) {
        // if we're not redirecting, reloading here can trigger an infinite loop
        window.location.reload(false); //reload the main app
      }
      return {
        ...result,
        cachedAuth: true
      }
    }

    // Case 3 - Not authenticated
    // If a cognito session is found, we must force a cognito-session logout
    const logoutResult = AuthService.processCognitoSession(params);
    return {
      ...result,
      ...logoutResult
    };
  }

  static async getTokens(refreshIfRequired = false) {
    let accessToken = getCookie(ACCESS_TOKEN_KEY);
    let idToken = getCookie(ID_TOKEN_KEY);
    const refreshToken = getCookie(REFRESH_TOKEN_KEY);
    if (refreshIfRequired && isTokenExpired(accessToken) && refreshToken) {
      try {
        const tokens = await AuthService.tokenRefresh();
        if (tokens) {
          accessToken = tokens.accessToken;
          idToken = tokens.idToken;
        }
      } catch (e) {
        Logger.log("Failed to refresh tokens", e);
      }
    }
    return { accessToken, idToken, refreshToken };
  }

  /**
   * Determines if the token is expired
   * @return {boolean}
   */
  static tokenExpired() {
    const accessToken = getCookie(ACCESS_TOKEN_KEY);
    return isTokenExpired(accessToken);
  }

  /**
   * Attempts to refresh the user's tokens, based on their refreshToken
   * @return {Promise<oAuthTokenCollection>}
   */
  static async tokenRefresh() {
    let tokens;
    try {
      const refreshToken = getCookie(REFRESH_TOKEN_KEY);
      if (refreshToken) {
        const ret = await ApiRequest.post(`/auth/token/refresh`, { clientType: CLIENT_TYPE, refreshToken });
        if (ret.success && ret.authentication) {
          tokens = {
            accessToken: ret.authentication.accessToken,
            idToken: ret.authentication.idToken,
            refreshToken: ret.authentication.refreshToken ?? refreshToken
          };
        }
      }
    } finally {
      if (!tokens) {
        Logger.debug("refreshTokens - FINALLY - Logout");
        AuthService.logout();
      }
    }
    return tokens;
  }

  /***
   * Returns the current user based on the authenticated user's credentials within cookies.
   * If we have an explicit accessToken passed in, we will attach that to the authorization header;
   * this allows our client portals to access this method. Clients won't have access to the unified
   * site's cookies
   * @param accessToken
   * @return {Promise<Object>}
   */
  static async getCurrentUser(accessToken = undefined) {
    return ApiRequest.get(`/auth/me`, undefined, buildHeaders(accessToken));
  }

  /**
   * Login using the SRP auth flow
   * @param username - user's username (email or phoneNumber)
   * @param password - user's password
   */
  static async login(username, password) {
    const config = await AuthService.getClientConfig();

    //Calculate SRP_A based on user pool name and password
    const userPool = new UserPool(config.poolName);
    const clientChallenge = await userPool.getClientChallenge({ username, password });
    let srpA = clientChallenge.calculateA().toString("hex");

    //Initiate login w/ the username + srpA
    const { challenge, srp, response } = await ApiRequest.post(`/auth/login`, {
      clientType: CLIENT_TYPE,
      username,
      srpA
    });
    clientChallenge.user.username = challenge.uid; //set username to internal cognito id (sub)

    //Create session from srpB + salt then calculate the signature
    const session = clientChallenge.getSession(srp.srpB, srp.salt);
    response.timestamp = new DateHelper().getNowString();
    response.signature = session.calculateSignature(challenge.secretBlock, response.timestamp);

    const result = await authenticationChallenge(challenge, response);
    return buildLoginResponse(result);
  }

  static async authorizeClient(params) {
    const { clientType, redirectUri, state, codeChallenge } = params;

    const searchParams = new URLSearchParams({
      clientType,
      redirectUri,
      ...(codeChallenge ? { codeChallenge } : {}),
      ...(state ? { state } : {})
    });
    return launchUri(`/auth/client/authorize?${searchParams.toString()}`);
  }

  /**
   * @param clientType
   * @param redirectUri
   * @param code
   * @param codeVerifier
   * @return {Promise<AuthApiResponse>}
   */
  static async getTokensForClient(clientType, redirectUri, code, codeVerifier = undefined) {
    const result = await ApiRequest.post(`/auth/client/token`, {
      grantType: "authorization_code",
      clientType,
      redirectUri,
      code,
      ...(codeVerifier ? { codeVerifier } : {})
    });
    return result;
  }

  /**
   * @param clientType
   * @param {string} refreshToken - The user's JWT Refresh token
   * @return {Promise<oAuthTokenCollection>}
   */
  static async refreshTokensForClient(clientType, refreshToken) {
    return ApiRequest.post(`/auth/client/token`, {
      grantType: "refresh_token",
      clientType,
      refreshToken
    });
  }

  /**
   * Indicates if the user (by email) uses an external provider
   * @param email
   * @return {Promise<{isExternal: boolean, provider: *, domain: string}>}
   */
  static async isExternalProviderDomain(email) {
    const parts = /.*@(.*)/gm.exec(email);
    const domain = parts?.length > 1 ? parts[1].toLowerCase() : undefined;

    const config = await AuthService.getClientConfig();
    const provider = (config?.idProviders || {})[domain]?.provider;
    return {
      isExternal: provider !== undefined,
      domain,
      provider
    };
  }

  /**
   * Initiates the Code Flow authentication process for external provider
   * @param provider
   * @param {string} state - Base64 encoded string
   * @return {Promise<*>}
   */
  static async authorizeProvider(provider, state= undefined) {
    const pkceKey = generateRandom(128);
    setLocalStorageWithExpiration(PKCE_STORAGE_KEY, pkceKey, 300);
    const codeChallenge = generateChallenge(pkceKey);

    const searchParams = new URLSearchParams({
      clientType: CLIENT_TYPE,
      provider,
      redirectUri: AuthService.SSORedirectUri,
      codeChallenge,
      ...(state ? { state } : {})
    });
    return launchUri(`/auth/provider/authorize?${searchParams.toString()}`);
  }

  /**
   * Processes an external provider's code to exchange it for tokens.
   * @param code
   * @return {Promise<{redirectPath: string, success: boolean, params: Object | undefined, error: Error | undefined}>}
   */
  static async processCodeExchangeForProvider(code) {
    const result = {
      success: false,
      redirectPath: "/redirect",
      params: undefined,
      error: undefined
    };

    if (!code) {
      return result;
    }

    const state = decodeClientStateFromQS();
    delete state.error;
    result.params = state;

    try {
      const codeVerifier = getLocalStorageWithExpiration(PKCE_STORAGE_KEY);
      const apiResult = await ApiRequest.post(`/auth/provider/token`, {
        clientType: CLIENT_TYPE,
        redirectUri: AuthService.SSORedirectUri,
        code,
        ...(codeVerifier ? { codeVerifier } : {}),
      });
      result.success = apiResult?.result === authResponseCodeEnum.logged_in;
      removeFromLocalStorage(PKCE_STORAGE_KEY);
    } catch (e) {
      // If we get an error from this call, it will explicitly be a 'cognitoError'.
      // This means it will be a generic NotAuthorized error w/the message holding the
      // actual cognito error message
      result.error = e.message.toUpperCase();
    }
    result.redirectPath = `/`;
    return result;
  }

  static logout() {
    removeCookie(ID_TOKEN_KEY);
    removeCookie(ACCESS_TOKEN_KEY);
    removeCookie(REFRESH_TOKEN_KEY);

    // Write a "clear session" flag to storage. This informs the system that it
    // must clear the cognito session before the next login.
    //
    // Clearing the cognito session requires the browser visit our cognito domain
    // in a normal browser window so the cognito auth response can send back a
    // SET-COOKIE header that clears the session cookie.
    setLocalStorage(CLEAR_SESSION_STORAGE_KEY, true);
  }

  /**
   * Determines if a clear session flag exists in local storage and if  required,
   * will return an indicator and a path for redirection
   * @param params
   * @return {{forceLogout: boolean}|{forceLogout: boolean, redirectPath: string}}
   */
  static processCognitoSession(params) {
    const clearSessionFlag = getLocalStorage(CLEAR_SESSION_STORAGE_KEY);
    if (!clearSessionFlag) return { forceLogout: false };

    setLocalStorage(REDIRECT_PATH_STORAGE_KEY, `${window.location.pathname}${window.location.search}`);
    const searchParams = new URLSearchParams({
      ...params,
      initiateLogout: true
    });
    return {
      forceLogout: true,
      redirectPath: `/logout?${searchParams.toString()}`
    };
  }

  /**
   * This will initiate and eventually complete the logout process for a cognito session (cookie) on our
   * cognito Auth domain. This will be called twice in the logout process.
   * 1) Redirects to the cognito auth /logout endpoint
   * 2) Handles redirection back from the cognito auth call
   * @param params
   * @return {Promise<*>}
   */
  static async logoutCognitoSession(params) {
    let { initiateLogout } = params;

    //Part 1 - The portal initiated the logout process to force a hard redirect
    if (initiateLogout) {
      const searchParams = new URLSearchParams({
        clientType: CLIENT_TYPE,
        redirectUri: AuthService.SSOLogoutUri
      });

      const { logoutUrl } = await ApiRequest.get(`/auth/logout?${searchParams.toString()}`);

      // redirect to our cognito Auth domain to complete the session logout, which will remove the cognito
      // session cookie.  This will allow for re-prompting for 3rd party Idp users to pick the user they are
      // logging in with (e.g. Google account chooser)
      return launchUri(logoutUrl);
    }

    //Part 2 - This normally occurs from a redirect from our cognito domain
    const redirectPath = getLocalStorage(REDIRECT_PATH_STORAGE_KEY);
    removeFromLocalStorage(CLEAR_SESSION_STORAGE_KEY);
    removeFromLocalStorage(REDIRECT_PATH_STORAGE_KEY);
    return {
      redirectPath: `${redirectPath || "/"}`
    }
  }

  /***
   * Changes the user's password.
   * If we have an explicit accessToken passed in, we will attach that to the authorization header;
   * this allows our client portals to access this method. Clients won't have access to the unified
   * site's cookies
   * @param currentPassword
   * @param newPassword
   * @param accessToken
   * @return {Promise<AuthApiResponse>}
   */
  static async changePassword(currentPassword, newPassword, accessToken = undefined) {
    return ApiRequest.put(`/auth/password`, { currentPassword, newPassword }, buildHeaders(accessToken));
  }

  static async forgotPassword(username, clientType = "portal", options = undefined) {
    return ApiRequest.post(`/auth/password/forgot`,
    {
      clientType,
      username,
      ...( options ? { options } : {} )
    });
  }

  static async resetPassword (username, code, newPassword, clientType="portal") {
    return ApiRequest.post(`/auth/password/reset`, {
      clientType,
      username,
      code,
      newPassword
    });
  }

  static async passwordChallenge(challenge, newPassword) {
    const result = {
      type: authResponseCodeEnum.new_password_required,
      newPassword
    };
    const ret = await authenticationChallenge(challenge, result);
    return buildLoginResponse(ret);
  }

  static async mfaRequiredChallenge(challenge, { phoneNumber }) {
    const result = {
      type: authResponseCodeEnum.mfa_setup_required,
      phoneNumber
    };
    const ret = await authenticationChallenge(challenge, result);
    return buildLoginResponse(ret);
  }

  static async smsMfaChallenge(challenge, code) {
    const response = {
      type: authResponseCodeEnum.sms_mfa,
      code: code
    };
    const ret = await authenticationChallenge(challenge, response);
    return buildLoginResponse(ret);
  }

  static async setUserMfaSms (enabled, preferred, accessToken = undefined) {
    return ApiRequest.post(`/auth/mfa/sms`, { enabled, preferred }, buildHeaders(accessToken));
  }

  static async sendAttributeVerificationCode(attribute = "phoneNumber", clientType = "portal") {
    return ApiRequest.post(`/auth/user/${attribute}/verify/code`, { clientType });
  }

  static async verifyUserAttribute(code, attribute = "phoneNumber") {
    return ApiRequest.post(`/auth/user/${attribute}/verify`, { code });
  }

  /**
   * Updates the current user's attributes
   * If we have an explicit accessToken passed in, we will attach that to the authorization header;
   * this allows our client portals to access this method. Clients won't have access to the unified
   * site's cookies
   * @param user
   * @param clientType
   * @param accessToken
   * @return {Promise<AxiosResponse<any>>}
   */
  static async updateUser (user, clientType = undefined, accessToken = undefined) {
    const options = {
      locale: "en"
    };
    return ApiRequest.put(`/auth/user`, { clientType, user, options }, buildHeaders(accessToken));
  }

  static async startUserRegistration (clientType, token) {
    return ApiRequest.post("/auth/user/registration/start", { clientType, token});
  }

  static async finalizeUserRegistration (clientType, token, email, phoneNumber) {
    return ApiRequest.post("/auth/user/registration/finalize", {
      clientType,
      token,
      email,
      phoneNumber
    });
  }
}

const buildHeaders = (accessToken) => {
  let headers;
  if (accessToken) {
    headers = { Authorization: accessToken }
  }
  return headers;
}

const authenticationChallenge = async (challenge, response) => {
  return ApiRequest.post(`/auth/challenge`, { clientType: CLIENT_TYPE, challenge, response });
};
