import { History } from "history";
import { addOrReplaceQueryStringValues, getQueryStringParams } from "../http";

/**
 * Updates the query param for "email" to the provided value
 *
 * - If "username" query param is the same, also updates "username" to match
 */
export function updateEmailQueryParam(history: History, value?: string) {
  const currentParams = getQueryStringParams();

  const newSearchValues: Parameters<typeof addOrReplaceQueryStringValues>[0] = { email: value };

  if (currentParams.username && currentParams.username === currentParams.email) {
    newSearchValues["username"] = value;
  }

  history.replace({ search: addOrReplaceQueryStringValues(newSearchValues) });
}
