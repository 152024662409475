import { Configuration } from "rollbar";
import { version } from "lib/config/pkgJson";
import Environment, { PROD } from "../../utils/environment";

const rollbarConfig: Configuration = {
  accessToken: Environment.rollbarToken,
  environment: Environment.environment,
  payload: {
    environment: Environment.environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: version
      }
    }
  },
  autoInstrument: true,
  scrubTelemetryInputs: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  logLevel: Environment.environment === PROD ? 'warning' : 'debug'
};
export default rollbarConfig;
