enum authResponseCodeEnum {
  new_password_required = "new_password_required",
  confirmation_code_required = "confirmation_code_required",
  logged_in = "logged_in",
  refreshed = "refreshed",
  sms_mfa = "sms_mfa",
  code_flow_initiated = "code_flow_initiated",
  mfa_setup_required = "mfa_setup_required",
}
export default authResponseCodeEnum;
