import axios, { Axios } from "axios";
import { AuthService } from "../auth";
import {Logger} from "../../utils/logging";
import { ApiError } from "./apiError";

export const setupApi = (instance: Axios) => {
  // request override
  instance?.interceptors.request.use(
    async function (config) {
      if (!config.headers) {
        config.headers = {}
      }

      //If we haven't set an explicit authorization, we will attach the token from storage
      if (!config.headers.Authorization) {
        const accessToken = AuthService.accessToken;
        if (accessToken) {
          config.headers.Authorization = accessToken;
        }
      }

      return config;
    },
    async function (error) {
      Logger.debug("REQUEST - on rejected", error)
      throw new ApiError(error);
    }
  );

  // response override
  instance?.interceptors.response.use(
    async function (response) {
      const { data } = response.data;
      return data;
    },
    async function (error) {
      const originalRequest = error.config;
      const status = error?.response?.status;
      if ((status === 403 || status === 401) && !originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken = AuthService.accessToken;

        if (!accessToken) {
          Logger.debug("FAILED RETRY");
          const tokens = await AuthService.getTokens(true);
          if (tokens?.accessToken) {
            originalRequest.headers.Authorization = tokens.accessToken;
          }
        }

        return axios(originalRequest);
      } else {
        const status = error?.response?.status;
        if (status === 403 || status === 401) {
          AuthService.logout();
        }
        throw new ApiError(error);
      }
    }
  );
};
